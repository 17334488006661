.app-nav-container {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem, 0rem;
  margin-left: 1.8rem;
}

.app-nav-menu {
  margin-left: auto;
  margin-right: 5rem;
}

.app-nav-menu ul {
  display: flex;
  align-items: start;
  justify-content: flex-end;
  padding: 0;
}

.app-nav-menu li {
  list-style-type: none;
  margin: 0.7rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown:hover .dropdown-button {
  background-color: #2075a7;
}


.dropdown-menu {
  display: none;
  position: absolute;
  min-width: 130px;;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-item {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: 1px solid black;
  border-bottom: 1px;
  border-left: 0;
  border-right: 0;
}

.dropdown-item:hover {
  background-color: #121312;
  color: #319bd8;
  cursor: pointer;
}

.dropdown-item.logout:hover {
  background-color: #121312;
  color: red;
  cursor: pointer;
}





@media screen and (max-width: 768px) {
  .app-nav-container {
    display: flex;
    margin: 0;
  }
  .app-nav-menu ul {
    /* position: absolute; */
    /* top: 25px; */
    /* left: 0px; */
    /* flex-direction: column; */
    /* width: 90%; */
    height: calc(-1vh - 8018px);
    /* justify-content:end; */
    display: none;
  
  }

  .app-nav-menu.expanded ul {
    display: block;
  }

  .app-nav-menu li {
    margin: 0.5rem;
  }
}
