.health-options-container {
  color: #fff;
  
}

.health-option-container {
  display: inline-grid;
  
  padding: 5px;
  margin-bottom: 15px;
}

.health-option-button {
  background-color: #ffffff;
  border: none;
  border-radius: 8px;
  color: black;
  padding: 8px 32px;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.custom-health-input {
  display: inline-block;
  text-align: center;
  font-size: 1.5rem;
  width: 50px;
  height: 30px;
  margin: 10px;
}

.life-text {
  padding-bottom: 10px;
}

.game-setup-list {
  display: flex;
  justify-content: center;
  padding: 0;
}

.game-setup-list ul {
  flex-direction: column;
  width: 100%;
  margin: 0.7rem; 
  display: block;
  padding: 0;
}

.game-setup-list li {
  list-style-type: none;
  margin: 0.7rem;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
