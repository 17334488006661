.logo-text {
  text-align: left;
  font-weight: bold;
  font-size: 6rem;
  transition:ease 0.3s;
}

.nav-container {
  height: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem, 0rem;
}

.nav-logo {
  height: 75px;
  width: 75px;
  padding-right: 10px;
}

.logo-item {
  margin-left: 10rem;
}

.nav-menu {
  margin-left: auto;
  margin-right: 5rem;
}

.nav-menu ul {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.nav-menu li {
  list-style-type: none;
  margin: 0.7rem;
}

a:link {
  text-decoration: none;
}

.hamburger {
  padding: 0.5rem;
  margin-right: 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  transition: ease-out 0.4s;
  display: none;
}
.hamburger:hover {
  color: #2075a7;
}

@media screen and (max-width: 768px) {
  .nav-container {
    padding: 0;
  }

  .logo-text {
    text-align: center;
    font-weight: bold;
    font-size: 3rem;
  }

  .logo-item {
    margin-left: 1rem;
  }


  .hamburger {
    display: block;
  }

  .nav-menu ul {
    position: absolute;
    top: 165px;
    left: 0px;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 78px);
    justify-content:inherit;
    display: none;
  }

  .nav-menu.expanded ul {
    display: block;
  }

  .nav-menu li {
    margin: 0.5rem;
  }
}
