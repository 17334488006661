.lobby-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-size: cover;
  min-height: 100%;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.game-setup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  height: 50vh;
  width: 500px;
  padding: 2rem;
  background-color: #1e211e;
  margin: 6rem;
}

@media screen and (max-width: 960px) {
  .lobby-container {
    width: 100%;
    margin-top: 6rem;
    padding: 0;
  }

  .helper-text {
    width: 350px;
  }
}
