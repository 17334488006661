.form-container {
    display: flex;
    justify-content: center;
    margin: 1.5rem;
}

.form-field {
display: block;
text-align: center;
}

.form-input-label {
    text-align: start;
    display: block;
    margin-left: 10px;
}

.form-input-bar {
    display: inline-block;
    width: 300px;
    height: 30px;
    margin: 10px;
}

.submit-button {
    width: 300px;
    height: 35px;
    background-color: #319bd8;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px;
}

@media screen and (max-width: 768px) {
   input {
    font-size: 16px;
   }

   .form-field {
    width: auto;
   }
}