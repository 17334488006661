.user-card {
  display: flex;
  flex-direction: column;
  color: white;
  width: 400px !important;
  height: 450px !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.394);
  border-radius: 15px;
  margin: 20px;
}

.user-pfp {
    height: 200px;
    width: auto;
    border: 1px solid black;
    border-radius: 15px;
    box-shadow: 0 1px 4px 0 rgba(131, 131, 131, 0.394);
}

.username-card {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 10px;
}

.user-health {
  font-size: 2rem;
  font-weight: 700;
}

.health-counter-button {
  font-size: 2rem;
  font-weight: 900;
  width: 65px;
  height: 65px;
  border: none;
  border-radius: 50%;
  outline: none;
  background: white;
  margin: 10px 25px;
  color: black;
  cursor: pointer;

}

.counter-row {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.health-counter-button:hover {
    transform: scale(1.1);
}

.health-counter-button:active {
    transform: scale(1.1);
    background: rgba(250, 250, 250, 0.15);
}

.counter-buttons {
    list-style-type: none;
}

.counter-button-picker-icon {
    margin: 0px 15px;
    color: white;
}

@media screen and (max-width: 960px) {
  .user-card {
    width: 215px !important;
    height: 275px !important;
  }

  .user-pfp {
    height: 90px;
    width: auto;
  }

  .user-health {
    padding: 5px;
  }
  .counter-row {
    width: 215px;
  }

  .counter-buttons {
    margin: 10px;
  }

  .health-counter-button {
    font-size: 1.7rem;
    text-align: center;
    font-weight: 900;
    width: 55px;
    height: 55px;
    margin: 7px 15px;
    padding: 0;
  }
}