.game-card {
  display: flex;
  flex-direction: column;
  /* color: white; */
  width: 300px;
  height: 75px;
  /* align-items: flex-start; */
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.394);
  border-radius: 15px;
}

.game-card-title {
    text-align: start;
    color: white;
    padding: 10px;
}


.game-card-date {
    text-align: right;
    font-size: small;
    color: rgb(187, 187, 187);
    padding: 10px;
}
