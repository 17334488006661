.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  justify-content: center;
  margin-left: 9rem;
  margin-right: 9rem;
}

.faq-heading {
  margin-bottom: 5rem;
}

.faq-title {
  margin-bottom: 1rem;
  margin-top: 2.2rem;
}

.faq-description {
  line-height: 2;
}


@media screen and (max-width: 768px) {
  .faq-container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}