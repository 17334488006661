.left-feature-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 25px;
  margin-right: 255px;
  margin-left: 100px;
  align-items: center;
}

.right-feature-item {
  display: flex;
  justify-content: flex-end;
  padding: 25px;
  margin-right: 100px;
  margin-left: 255px;
  align-items: center;
}

.feature-title {
  font-weight: bold;
  font-size: 1.1rem;
}

.feature-image {
  width: 20rem;
  height: auto;
  margin: 10px;
}

.feature-description {
    width: 15rem;
    align-items: center;
    padding: 25px;
    margin: 5px;
    background-color: #111311;
    color: #7a88a6;
    font-size: 15px;
}

@media screen and (max-width: 768px) {
    .left-feature-item {
        flex-direction: column;
        margin: 15px;
    }

    .right-feature-item {
        flex-direction: column-reverse;
        margin: 15px;
    }
}