.player-card {
  display: flex;
  flex-direction: column;
  color: white;
  width: 200px !important;
  height: 200px !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.394);
  border-radius: 15px;
  margin: 20px;
}

.player-col {
  /* padding: 5px; */
}

.player-pfp {
  height: 100px;
  width: 100px;
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 0 1px 4px 0 rgba(131, 131, 131, 0.394);
}

.player-username {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 10px;
}

.player-health {
  font-size: 1.6rem;
  font-weight: 700;
}

.player-text {
  font-size: 0.7rem;
  font-weight: 700;
}

.player-row {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content:flex-end;
  padding: 0;
}

.player-counter-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
}

.player-counter-button-picker-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 30px;
  width: auto;
  margin: 5px;
}

@media screen and (max-width: 960px) {
  .player-card {
    width: 120px !important;
    height: 137.5px !important;
    justify-content:center;
    align-items: center;
  }

  .player-col {
    height: 137.5px;
  }

  .player-pfp {
    height: 55px;
    width: 55px;
  }

  .player-health {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .player-text {
    font-size: 0.7rem;
    font-weight: 600;
  }

  .player-username {
    font-size: 10px;
    font-weight: 700;
    margin: 4px;
    margin-bottom: 10px;
  }

  .player-counter-buttons {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    padding: 6px;
    list-style-type: none;
    margin: 0;
    width: 15px;
  }

  .player-counter-button-picker-icon {
    display: flex;
    flex-direction: row;
    height: 10px;
    /* width: auto; */
    margin-left: 8px;
  }
}
