.footer-container {
  background-color: black;
  padding-bottom: 5rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.footer-link {
    color: rgb(131, 131, 131);
    margin: 3rem;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-copyright {
    text-align: center;
  font-size: small;
  color: rgb(131, 131, 131);
  line-height: 1;
  margin-top: 1rem;
  
}

@media screen and (max-width: 768px) { 
  .footer-container {
    padding: 0;
  }

  .footer-links {
    width: 100%;
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .footer-link {
    margin: 1.3rem;
  }
  .footer-copyright {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  }
}