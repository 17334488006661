
.game-nav-menu {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin-right: 5rem;
  }
  
  .game-nav-menu ul {
    justify-content: flex-start;
    padding: 0;
  }
  
  .game-nav-menu li {
    list-style-type: none;
    margin: 0.7rem;
  }
  
  .game-settings:hover {
    color: #2075a7;
  }
  
  .game-menu {
    display: flex;
    z-index: 1;
  }
  
  .game-settings-menu {
    position: absolute;
  }
  
  .game-settings-menu ul {
    position: absolute;
    right: 75px;
    z-index: 1;
    width: 90%;
    height: calc(25vh - 78px);
    justify-content: center;
    display: none;
  }
  
  .game-settings-menu.expanded ul {
    display: block;
  }

  @media screen and (max-width: 768px) {  
    .game-menu {
      display: flex;
      flex-direction: column;
    }
  }