.pro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    justify-content: center;
    margin-left: 9rem;
    margin-right: 9rem;
}

.pro-heading {
    width: 55rem;
    text-align: center;
}

.pro-feature-list {
    width: 35rem;
    justify-content: center;
    padding-bottom: 35px;
}

.privacy-policy {
    margin-bottom: 45px;
}

@media screen and (max-width: 768px) {
    .pro-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .pro-heading {
        width: auto;
    }

    .pro-feature-list {
        width: auto;
    }
}