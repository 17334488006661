.modal {
    /* display: none; */
    /* position: fixed; */
    z-index: 1;
    background-color: rgba(214, 62, 62, 0.979);
    
  }

  .modal-content {
    background-color: #1e211e;
    position: absolute;
    top: 20%;
    left: 30%;
    width: 40%;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid gray;
    /* box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.394); */
  }

  .modal-title {
    font-weight: bold;
    text-align: center;
  }

  .modal-form {
    align-content: center;
  }

  .close {
    color: white;
    float: right;
  }
  
  .close:hover {
    color: red;
    cursor: pointer;
  }

  .modal-username {
    padding: 10px;
  }
