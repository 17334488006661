.home-container {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url(../../assets/MTGBackground.jpeg);
}
.home-hero {
  align-items: center;
  padding: 150px 75px 100px 75px;
  font-size: 4rem;
}

.home-highlight {
  align-items: center;
  padding-top: 100px;
  padding-bottom: 150px;
}

.app-store-link {
  width: 12rem;
  height: auto;
}

.home-callout {
  display: inline-block;
  max-width: 615px;
  font-size: 1.2rem;
  padding: 25px 75px;
  margin: 75px;
  /* background-color: #c4d3ca; */
  background-color: rgb(248, 231, 185);
  color: black;
}

.home-row {
  background-color: black;
}

.highlight-row {
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: #023047;
  /* background-color: #1e211e; */
}

.hero-image {
  padding-top: 75px;
  max-width: 100%;
  height: auto;
}
.home-more-container {
  display: flex;
  text-align: center;
  height: fit-content;
  background-color: 1e211e;
  justify-content: center;
}

.home-more {
width: 15rem;
text-align: center;
margin: 7rem;
}

.bold-text {
  font-weight: bold;
}

/* not found page */
.dino-img {
  width: 150px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .home-highlight {
    font-size: 3rem;
  }

  .home-more-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 0;
  }
  .home-more {
    margin: 0rem;
    padding: 3rem;
  }
}
