* {
  font-family: "Poppins", sans-serif;
}

html {
  height: 100%;
  background-color: #1e211e;
}

#page {
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-size: cover;
  min-height: 100%;
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.header {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
}
.brand-color {
  color: #319bd8;
}

.black-text {
  color: black;
}

.white-text {
  color: #fff;
}

.alert {
  color: red;
  padding: 10px;
}

a {
  color: #319bd8;
}

.brand-button-main {
  background-color: #319bd8;
  border: none;
  border-radius: 8px;
  color: white;
  padding: 8px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.brand-button-main.delete {
  background-color: red;
}

.brand-button-main.delete:hover {
  background-color: rgb(169, 4, 4);
}

.brand-button-main:hover {
  background-color: #2075a7;
  border: none;
  border-radius: 8px;
  color: white;
  padding: 8px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}


@media screen and (max-width: 768px) { 
  iframe {
    max-width: 100%;
}

.rst-copyright img {
    max-width: 100%;
    height: auto;
}

  html {
    width: 100%;
  }

  .container {
    width: 100%;
  }

  .row {
    flex-direction: column;
  }
}