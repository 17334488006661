.game-container {
  display: flex;
  width: 1000px;
  justify-content: flex-start;
  height: 50vh;
  margin-left: 3px;
}

.lobby-code {
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 15px; */
}

.lobby-code.creating {
  padding-bottom: 10px;
}

.user-grid {
  display: grid;
  grid-template-columns: repeat(1fr 2fr);
}

.player-grid {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  height: 325px;
  display: flex;
  align-content: start;
  justify-content: flex-start;
  padding: 0;
}

@media screen and (max-width: 960px) {
  .player-grid {
    flex-direction: row;
    flex-flow: row wrap;
  }
}

