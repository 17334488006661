.avatar-form-field {
  display: flex;
  flex-direction: column;
  width: 325px;
}

.avatar-form-field span {
  text-align: start;
  margin-left: 10px;
}

.avatar-form-field div {
  align-items: center;
  justify-items: center;
}

.avatar-grid {
  display: inline-grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 15px;
  padding-bottom: 15px;
  width: 325px;
}

.avatar-grid-item {
  display: flex;
  border-radius: 15px;
  width: 85px;
  height: 85px;
  justify-content: space-between;
  flex-direction: column;
  border: 3px solid #319bd8;
}

.avatar-grid-item:hover {
  transform: scale(1.05);
}

.avatar-grid-item.selected {
  border: 15px;
  border: 3px solid black;
}

.horizontal-button {
    width: 150px;
    height: 35px;
    background-color: #319bd8;
    border: none;
    border-radius: 8px;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px; 
}