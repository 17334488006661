.friend-card {
    display: flex;
    flex-direction: row;
    width: 300px;
    height: 75px;
    box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.394);
    border-radius: 15px;
    align-items: center;
}

.friend-status {
    display: flex;
    font-size: small;
    padding: 5px;
}

.friend-status-text {
    padding-left: 5px;
}

.friend-pfp {
    height: 65px;
    width: 65px;
    border: 1px solid black;
    border-radius: 15px;
    box-shadow: 0 1px 4px 0 rgba(131, 131, 131, 0.394);
    margin-left: 5px;
    margin-right: 5px;
}

