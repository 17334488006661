.account-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-size: cover;
  min-height: 100%;
  text-align: center;
  align-content: center;
  margin: 0 150px;
}

.settings-container {
  color: white;
  display: flex;
  flex-flow: column;
  height: 100%;
  text-align: start;
  align-items: center;
  margin: 0 25rem;
  padding-bottom: 5rem;
}

.profile-info {
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: baseline;
  border: 2px solid #319bd8;
  border-top: 1px;
  border-left: 0;
  border-right: 0;
}

.profile-pic {
  width: 125px;
  height: auto;
  border: 1px solid black;
  border-radius: 15px;
  box-shadow: 0 1px 4px 0 rgba(131, 131, 131, 0.394);
}

.username {
  color: white;
  margin: 10px;
  position: relative;
  bottom: 10px;
}

.account-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-details {
  text-align: start;
  width: 440px;
  
}

.user-details.expanded ul {
  display: flex;
  flex-direction: column;
  list-style: none ;
  padding: 0;
}

.user-details ul {
  display: none;
}

.settings-container .text-field {
  text-align: start;
}

.recent-games {
  width: 300px;
}

.recent-games.expanded ul {
  transition: height 4s;
  display: flex;
  flex-direction: column-reverse;
  list-style: none;
  padding: 0;
}

.recent-games ul {
  display: none;
}

.recent-games li {
  padding-bottom: 15px;
}

.friends-list {
  width: 300px;
}

.friends-list.expanded ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.friends-list ul {
  display: none;
}

.friends-list li {
  padding-bottom: 15px;
}

.friends-list button {
  margin: 0;
}


@media screen and (max-width: 768px) {
  .account-container {
    align-content: flex-start;
    margin: 5px;
  }

  .profile-info {
    margin: 4;
    border: none;
  }

  .account-content {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1rem;;
  }

  .user-details {
    width: 100%;
  }

  .user-details.expanded ul {
    text-align: center;
  }

  .recent-games {
    width: 100%;
  }

  .recent-games ul {
    align-items: center;
  }

  .friends-list {
    width: 100%;
  }

  .friends-list ul {
    align-items: center;
  }

  .settings-container {
    margin: 0 2rem;
    padding-left: 10px;
    padding-right: 10px;
  }
}